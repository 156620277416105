// extracted by mini-css-extract-plugin
export var center = "player-module--center--8b89a";
export var intro = "player-module--intro--78a76";
export var left = "player-module--left--d4932";
export var miniplayer = "player-module--miniplayer--af3c1";
export var miniplayerWrapper = "player-module--miniplayerWrapper--50b17";
export var notready = "player-module--notready--7f3c3";
export var nowPlaying = "player-module--nowPlaying--c12c8";
export var overflow = "player-module--overflow--79c5c";
export var play = "player-module--play--e4838";
export var playFocus = "player-module--playFocus--e175e";
export var player = "player-module--player--aa142";
export var queue = "player-module--queue--3803e";
export var queueFocus = "player-module--queueFocus--53248";
export var queuePanel = "player-module--queuePanel--898f9";
export var queuePanelClose = "player-module--queuePanelClose--f129b";
export var queuePanelCloseFocus = "player-module--queuePanelCloseFocus--181ff";
export var queuePanelList = "player-module--queuePanelList--68b15";
export var queuePanelTitle = "player-module--queuePanelTitle--d6ed3";
export var right = "player-module--right--cc14f";
export var slider = "player-module--slider--f5c92";
export var spacer = "player-module--spacer--428dc";
export var timestamp = "player-module--timestamp--e0645";
export var track = "player-module--track--d7e7d";
export var trackDisabled = "player-module--trackDisabled--2420f";
export var volume = "player-module--volume--de2cc";
export var volumeControl = "player-module--volumeControl--0e571";
export var volumeFocus = "player-module--volumeFocus--481bf";
export var volumeSlider = "player-module--volumeSlider--1e795";
export var volumeWrapper = "player-module--volumeWrapper--05355";