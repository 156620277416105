

export function getViewID(path) {
  if (!path) return null;
  switch (path) {
    case '/':
      return 'intro';
    default:
      let p = path.split('/');
      let view = '';

      // console.log('split:', p);
      
      if (p.length === 2) {
        view = p[1];
        // console.log('=== 2')
        // console.log('getViewID: p: ', p);
      }
      else if (p.length > 2) {
        // console.log('> 2')
        // console.log('getViewID: p: ', p);
        if (p[p.length-1] === '') p.pop();
        p.shift();
        view = p.join('-');
      }

      // console.log('getViewID: view: ', view);

      if (view === 'the-index') return 'index'
      else return view;
  }
};

export function round(numDecimalPlaces, num) {
  let zeroes = '';
  for (let i = 0; i < numDecimalPlaces; i++) {
    zeroes += '0';
  }
  let modifier = parseInt('1' + zeroes);
  return Math.round(num * modifier) / modifier;
}

export function showGlobalPlayer(view) {
  switch (view) {
    case 'intro':
    case 'about':
    case 'gallery':
    case 'catalog':
    case 'events':
      return true;
    default:
      return false;
  }
}

export function throttle(func, duration) {
  let shouldWait = false;
  return function (...args) {
    if (!shouldWait) {
      func.apply(this, args)
      shouldWait = true;
      setTimeout(function () {
        shouldWait = false;
      }, duration)
    }
  }
}