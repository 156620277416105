import React, { useState, useEffect, useRef } from 'react';
import Button from './Button';
import Link from './Link';
import Text from './Text';
import Slider from './Slider';
import { classdFn as classNames } from 'classd';
// import { Howler } from 'howler';
import { round } from '../api/utils';
// import { default as useConstructor } from '@hooks/useSingleton';
// import { useFocusRing } from '@react-aria/focus';
import { TransitionGroup, Transition } from 'react-transition-group';

import PlayIcon from '@static/icons/play.inline.svg';
import PauseIcon from '@static/icons/pause.inline.svg';
import VolumeIcon from '@static/icons/volume-2.inline.svg';
import VolumeMuteIcon from '@static/icons/volume-mute.inline.svg';
import ChevronUp from '@static/icons/chevron-up.inline.svg';
import * as styles from '@styles/components/player.module.scss';

export default function Player(props) {

  const {
    player: _player,
    queue, 
    setQueue,
    view,
    miniplayer = false,
    ready = true,
  } = props;

  const player = _player.player;
  // const playing = _player.state.playing;

  // const [mute, setMute] = useState(false);
  const mute = _player.state.mute.value;
  const setMute = _player.state.mute.setState;
  const [volume, setVolume] = useState(player.volume());
  const [time, setTime] = useState(0);
  const [length, setLength] = useState(false);
  const [scrubbing, setScrubbing] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [showQueue, setShowQueue] = useState(false);
  const [width, setWidth] = useState(0);
  // const [playing, setPlaying] = useState()
  
  const timer = useRef(null);
  const lastVolume = useRef(null);
  const once = useRef(null);

  const handleSelectTrack = (id) => {
    player.setTrack(id);
    setQueue();
    _player.state.playing.setState(true);
    player.play();
  }

  useEffect(()=>{
    player.volume(volume);
  }, [volume]);

  useEffect(()=>{
    if (!once.current) { 
      setWidth(window.innerWidth);
      once.current = true;
    }
    const handleResize = () => {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setQueue()
    // console.log(time, _player.state.playing.value);
  }, [])

  useEffect(()=>{
    // console.log('playing value was updated', _player.state.playing.value);
    
    // Just need to subscribe to the playing state by adding it here
  }, [_player.state.playing.value])

  const handlePlayPause = () => {
    handleStarted();


    if (player.getTrack().sound.playing()) {
      cancelTimer();
      player.pause();
      _player.state.playing.setState(false);
    } else {
      // setTime(player.seek());
      // setTimer();
      if (time === 0) setTime(0.01);

      player.play();
      if (!_player.state.started.value) {
        _player.state.started.setState(true);
      }
      _player.state.playing.setState(true);
      
    }
  }

  const handleStarted = () => {
    // console.log('handleStarted fired', _player.state.started);
    if (!_player.state.started.value) 
      _player.state.started.setState(true);
  }

  
  // console.log('track', track);

  useEffect(()=>{
    player.current.sound.once('load', () => {
      setLength(Math.round(track.sound.duration()))
    });
    player.current.sound.once('play', () => {
      // setTimer();
      setQueue();
    })
    
    // player.current.sound.once('end', () => {
    //   console.log('resetti');
    //   if (_player.state.playing.value && !player.isGlobal()) {
    //     _player.state.playing.setState(false);
    //   }
    // });
    
      // if (!player.isGlobal()) _player.state.playing.setState(false);
      
    //   // cancelTimer();
    //   // setTime(0);
    //   // player.play();
    //   // player.next();
      
    // })

    setLength(Math.round(track.sound.duration()))
  }, [player.current]);

  useEffect(()=>{
    if (scrubbing || !ready) return;
    if (_player.state.playing.value) {
      setTimer();
    }
    
    return () => cancelTimer();
  }, [time, _player.state.playing.value]);

  useEffect(()=>{
    setTime(0);
    cancelTimer();
    player.seek(0);
  }, [ready]);

  const setTimer = () => {
    let increment = 1;
    timer.current = setTimeout(() => {
      // setTime(prev => 
      //   (prev + increment > length 
      //     ? length 
      //     : (prev + increment))
      // );
      // console.log(round(2, player.seek()));
      setTime(round(2, player.seek()));
    }, increment * 250);
  };

  const cancelTimer = () => {
    clearTimeout(timer.current);
  }

  const handleToggleQueuePanel = () => {
    if (showQueue) setShowQueue(false);
    else setShowQueue(true);
  }

  const handleVolumeToggle = () => {
    setMute();
  }

  const handleShowVolume = (show) => {
    if (show && width >= 992) {
      setShowVolume(true)
    } else setShowVolume(false);
  }

  const track = player.current;

  let min = `${Math.floor(Math.round(time) / 60)}`;
  let sec = `${Math.round(time - min * 60)}`;
  if (sec.length === 1) sec = '0' + sec;
  const timestamp = `${min}:${sec}`;


  

  const nowPlaying = [`${player.current.trackName} • ${player.current.artistName}`];

  let queueList = [];
  for (let id of queue) {
    let track = player.findTrack(id);
    if (track) queueList.push({ 
      id: id,
      name: track.trackName, 
      artist: track.artistName
     });
  }

  // queueList = [
  //   {id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},{id: 'id',name: 'track',artist: 'artist',},
  // ]

  // console.log('what', _player.state.playing.value);
  const noop = () => {}
  
  const transitionStyles = {
    entering: { opacity: 0 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0, },
    exited:  { opacity: 0, },
  };

  return (
    <>
    <div 
      className={classNames(
        styles.player, 
        styles[view],
        miniplayer ? styles.miniplayer : null,
        !ready ? styles.notready : null,
      )}>
      <div className={styles.left}>
        <Button onPress={ready ? handlePlayPause : noop} className={styles.play} focusClassName={styles.playFocus} disableDefaultClass>
          {_player.player.current.sound.playing() 
            ? <PauseIcon />
            : <PlayIcon />
          }
        </Button>
        <div className={styles.nowPlaying}>
          <TransitionGroup component={null}>
            {nowPlaying.map(text =>
              <Transition timeout={375} key={text}>
                {state =>
                  <span
                    style={{
                      transition: `opacity 375ms ease-in-out`,
                      position: `absolute`,
                      top: `50%`,
                      transform: `translateY(-50%)`,
                      // opacity: 0,
                      ...transitionStyles[state]
                    }}
                  >
                    {text}
                  </span>
                }
              </Transition>
            )}
          </TransitionGroup>
        </div>
        <div className={styles.overflow} />
      </div>
      <div className={classNames(styles.track, styles.center)}>
        <div className={styles.spacer} />
        <Slider
          className={classNames(
            styles.slider,
            !length ? styles.trackDisabled : null
          )}
          label="Track"
          value={ready ? [time] : [0]}
          minValue={0}
          maxValue={length ? length : 100}
          step={0.01}
          simple
          onChange={(values) => { 
            // if (!ready) return;
            player.seek(values[0]);
            setTime(
              values[0]
            );
          }}
          onMouseDown={()=>{
            // console.log('playing?', playing.value);
            // cancelTimer();
            setScrubbing(true);
            player.volume(0);
          }}
          onMouseUp={()=>{
            setScrubbing(false);
            // player.play();
            cancelTimer();
            setTimer();
            player.volume(volume);
          }}
        />
        <div className={styles.timestamp}>
          <span>{ready ? timestamp : '0:00'}</span>
        </div>
      </div>
      {/* <div>
        {queue.map(track => 
          <span onClick={() => handleSelectTrack(track)} key={track}>{track}, </span>)
        }
      </div> */}
      <div className={styles.right}>
        {!miniplayer && 
        <div className={styles.volumeWrapper}>
          {showVolume
            ? <div 
                className={styles.volumeControl}
                onMouseEnter={()=>{setShowVolume(true)}} 
                onMouseLeave={()=>{setShowVolume(false)}} 
              >
                <Slider
                  className={styles.volumeSlider}
                  label="Volume"
                  value={!mute ? [volume] : [0]}
                  minValue={0.0}
                  maxValue={1.0}
                  step={0.01}
                  simple
                  onMouseDown={() => { if (mute) setMute(false); }}
                  onChange={(values) => { 
                    // console.log('Volume set:', values); 
                    setVolume(
                      Math.round(values[0] * 10) / 10
                      // values[0]
                    ); 
                  }}
                />
              </div>
            : !showQueue && 
              <Button 
                onPress={handleToggleQueuePanel}
                className={styles.queue}
                focusClassName={styles.queueFocus}
                disableDefaultClass
              >
                <span>Up Next</span><ChevronUp />
              </Button>
          }
          <Button 
            onMouseEnter={()=>{handleShowVolume(true)}}
            onMouseLeave={()=>{handleShowVolume(false)}}
            onPress={handleVolumeToggle} 
            className={styles.volume} 
            focusClassName={styles.volumeFocus} 
            disableDefaultClass
          >
            {mute 
              ? <VolumeMuteIcon />
              : <VolumeIcon />
            }
          </Button>
        </div>
        }
      </div>
      <QueuePanel 
        open={showQueue} 
        close={() => setShowQueue(false)}
        queue={queueList}
        setTrack={handleSelectTrack}
      />
    </div>
    {miniplayer &&
      <div className={styles.volumeWrapper}>
        {showVolume &&
        <div 
            className={styles.volumeControl}
            onMouseEnter={()=>{setShowVolume(true)}} 
            onMouseLeave={()=>{setShowVolume(false)}} 
          >
            <Slider
              className={styles.volumeSlider}
              label="Volume"
              value={!mute ? [volume] : [0]}
              minValue={0.0}
              maxValue={1.0}
              step={0.01}
              simple
              onMouseDown={() => { if (mute) setMute(false); }}
              onChange={(values) => { 
                // console.log('Volume set:', values); 
                setVolume(
                  Math.round(values[0] * 10) / 10
                  // values[0]
                ); 
              }}
            />
          </div>
        }
        <Button 
          onMouseEnter={()=>{handleShowVolume(true)}}
          onMouseLeave={()=>{handleShowVolume(false)}}
          onPress={handleVolumeToggle} 
          className={styles.volume} 
          focusClassName={styles.volumeFocus} 
          disableDefaultClass
        >
          {mute 
            ? <VolumeMuteIcon />
            : <VolumeIcon />
          }
        </Button>
      </div>
    } 
    </>
  );
}

function QueuePanel({ open, close, queue, setTrack }) {

  const handleItemClick = (id) => {
    setTrack(id);
    close();
  }

  return (
    <>
      {open &&
        <div className={styles.queuePanel}>
          <div className={styles.queuePanelTitle}>
            <Text h2>Up Next</Text>
            <Button
              onPress={close}
              className={styles.queuePanelClose}
              focusClassName={styles.queuePanelCloseFocus}
              disableDefaultClass
            >
              <ChevronUp />
            </Button>
          </div>
          <ul className={styles.queuePanelList}>
            {queue.length > 0 
              ? queue.map(item => 
                  <li key={item.id} onClick={() => handleItemClick(item.id)}>
                    <span>{`${item.name} • ${item.artist}`}</span>
                  </li>
                )
              : 'Loading queue...'
            }
          </ul>
        </div>
      }
    </>
  );
}