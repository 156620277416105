import React, { useState } from 'react';
import Link from './Link';
import Button from './Button';
import { classdFn as classNames } from 'classd';
import MenuIcon from '@static/icons/menu.inline.svg';
import CloseIcon from '@static/icons/x.inline.svg';
import * as styles from '@styles/components/nav.module.scss';

export default function Nav() {

  const items = [
    {
      to: '/gallery',
      label: 'Gallery'
    },
    {
      to: '/about',
      label: 'About'
    },
    {
      to: '/',
      label: 'Wrist Game Worldwide',
    },
    {
      to: '/catalog',
      label: 'Catalog'
    },
    {
      to: '/the-index#events',
      label: 'Events'
    },
  ]

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (open) setOpen(false);
    else setOpen(true);
  }

  // const handleClick = () => {
  //   setOpen(false);
  // }
  return (
    <>
      <div className={styles.container}>
        <Button className={styles.mobilebar} onPress={handleOpen} disableDefaultClass>
          {!open 
            ? <MenuIcon />
            : <CloseIcon className={styles.close} />
          }
          <div className={styles.title}>Wrist Game</div>
        </Button>
        <ul className={classNames(styles.list, styles.left)}>
          <NavLink to={items[0].to} label={items[0].label} />
          <NavLink to={items[1].to} label={items[1].label} />
        </ul>
        <ul className={classNames(styles.list, styles.center)}>
          <NavLink to={items[2].to} label={items[2].label} />
        </ul>
        <ul className={classNames(styles.list, styles.right)}>
          <NavLink to={items[3].to} label={items[3].label} />
          <NavLink to={items[4].to} label={items[4].label} />
        </ul>
      </div>

      <div className={classNames(styles.tray, open ? styles.open : null)}>
        <ul className={classNames(styles.list, styles.mobile)}>
          <NavLink to={items[2].to} label="Home" onClick={handleOpen} />
          <NavLink to={items[0].to} label={items[0].label} onClick={handleOpen} />
          <NavLink to={items[1].to} label={items[1].label} onClick={handleOpen} />
          <NavLink to={items[3].to} label={items[3].label} onClick={handleOpen} />
          <NavLink to={items[4].to} label={items[4].label} onClick={handleOpen} />
        </ul>
      </div>
    </>
  );
}


function NavLink({to, label, onClick}) {
  return (
    <li className={styles.item}>
      <Link
        to={to}
        className={styles.link}
        activeClassName={styles.active}
        onClick={onClick}
        disableDefaultClass
        >
          {label}
      </Link>
    </li>
  );
}