import React, { useState, useRef, useEffect } from 'react';
import paths from '../data/paths.json';
import View from './View';
import Nav from './Nav';
import { default as GlobalPlayer } from './Player';
import { classdFn as classNames } from 'classd';
import { default as Player } from '../api/player';
import { getViewID, showGlobalPlayer } from '../api/utils';
import * as styles from '@styles/components/layout.module.scss';


// Data
import projects from '../data/projects.json';
import artists from '../data/artists.json';
import setlist from '../data/order.json';
const URL_CDN = paths.assets;

export default function Layout(props) {
  const path = props.location.pathname;
  const view = getViewID(path);
  // console.log(props.location.state);
  const prevView = getViewID(props.location.state ? props.location.state.prev : null);
  const load = useRef(false);
  
  const _player = useRef(null);
  if (_player.current === null) _player.current = new Player({ 
    global: (showGlobalPlayer(view)),
    autoplay: false,
  });

  const player = _player.current;
  const [playing, setPlaying] = useState(player.getTrack().sound.playing());
  const [mute, setMute] = useState(false);
  const [started, setStarted] = useState(false);
  const [globalPlaying, setGlobalPlaying] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);
  
  const setQueueUI = (set) => {
    let q = [...player.getQueue()];
    let first = q.shift();
    q.push(first);
    if (set) {
      set(q);
      return;
    }
    else return q;
  }
  
  const [queue, setQueue] = useState([ ...setQueueUI() ]);

  const handleUpdateQueueUI = () => {
    setQueueUI(setQueue);
  }

  const updatePlayer = () => {

    let curr = view;
    let prev = prevView;
    // console.log(prev, curr);
    let prevShow = showGlobalPlayer(prev);
    let currShow = showGlobalPlayer(curr);

    if (load.current !== true) {
      // First load
      setPlayerReady(true);
      return;
    }

    if (prevShow && !currShow) {
      // stop
      // if already set to false, a page has handled it
      // if (player.isGlobal()) {
      //   // But if not, let's do it
      //   player.setGlobal(false);
      //   player.stop();
      //   setPlaying(false);
      // }
      
    } else if (!prevShow && currShow) {
      setPlayerReady(false);
      
      // console.log('===== !prev && curr =====');
      if (playing) {
        // Resume
        // fade would be cool
        // console.log('resuming');
        // player.fade('out', 1000);
        setTimeout(()=>{
          player.stop();
          // if(!) player.resetQueue();
          // console.log('loadQueue', player.loadQueue());
          player.loadQueue();
          player.setGlobal(true);
          player.play(null, true);
          player.current.sound.volume(0);
          player.fade('in', 1000);
          setPlaying(true);
          setPlayerReady(true);
        }, 0);
      } else {
        // console.log('not resuming');
        // Load from queue anyway to get ready for play (?)
        player.stop();
        // console.log('loadQueue', player.loadQueue());
        player.loadQueue();
        player.setGlobal(true);
        // if(!player.loadQueue()) player.resetQueue();
        player.play(null, true);
        player.pause();
        
        setPlaying(false);
        setPlayerReady(true);
      }
    }
  }

  const handleStart = () => {
    if (started === true) return;
    player.play();
    setPlaying(true);
    setStarted(true);
  }

  useEffect(()=>{
    // player.pause();
    updatePlayer();
    load.current = true;
  }, [props.location.pathname])

  useEffect(()=>{
  }, [playing])

  // if (_player.current) updatePlayer();
  
  let shouldShow = showGlobalPlayer(view);

  const setPlayingHelper = (isPlaying) => {
    if (player.isGlobal()) {
      setGlobalPlaying(isPlaying);
    }
    setPlaying(isPlaying);
  }
  const setMuteHelper = () => {
    player.mute(!mute);
    setMute(prev => !prev);
  }
  const playerProp = {
    player: player, 
    state: { 
      playing: {
        value: playing, 
        setState: setPlayingHelper
      },
      mute: {
        value: mute,
        setState: setMuteHelper,
      },
      globalPlaying: {
        value: globalPlaying,
      },
      started: {
        value: started,
        setState: setStarted,
      }
    },
  };

  // TODO: when in moving between global Player views and non, manage resetting the queue
  
  return (
    <div className={classNames(styles.base, styles[view])}>
      {(view !== 'intro' && view !== 'index') &&
        <Nav />
      }
      {view === 'intro' && 
        <div className={styles.fauxnav}>
          {!started && 'CLICK ANYWHERE TO LISTEN'}
        </div>
      }
      <View 
        view={view}
        player={playerProp} 
        queue={queue}
        setQueue={handleUpdateQueueUI}
        data={{
          projects: projects, 
          artists: artists,
          setlist: setlist,
          assets: {
            cdn: URL_CDN,
          }
        }}
        onClick={view === 'intro' ? handleStart : () => {}} 
        hasNav={(view !== 'intro' && view !== 'index')}
        hasPlayer={shouldShow}
        location={props.location}
      >
        {props.children}
      </View>
      {shouldShow && 
        <GlobalPlayer 
          player={playerProp} 
          queue={queue}
          setQueue={handleUpdateQueueUI}
          view={view}
          ready={playerReady}
        />
      }
    </div>
  )
}