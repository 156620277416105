import React, { useRef } from 'react';
import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { classdFn as classNames } from 'classd';
import Link from './Link';
import * as styles from '@styles/components/button.module.scss';

export default function Button(props) {
  const {
    kind,
    to,
    size,
    isDisabled,
    className,
    focusClassName,
    children,
    disableDefaultClass,
    onMouseEnter,
    onMouseLeave,
    style,
  } = props;

  const ref = useRef();
  const { buttonProps, isPressed } = useButton({
    ...props,
    preventFocusOnPress: true,
    elementType: to ? 'a' : 'button',
  }, ref);

  let { isFocusVisible, focusProps } = useFocusRing();

  const classes = classNames(
    !disableDefaultClass ? styles.btn : null,
    className,
    styles[kind],
    styles[size],
    isFocusVisible 
      ? focusClassName
        ? focusClassName 
        : styles.focused
      : null
  );

  return (to 
    // Render as Link
    ? <Link 
        {...mergeProps(buttonProps, focusProps)}
        to={to} 
        className={classes} 
        disableDefaultClass
        style={style}
      >
        {children}
      </Link>
    // Render a normal button
    : <button 
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        className={classes}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={style}
      >
        {children}
      </button>
  );
}
