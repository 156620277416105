import React from 'react'

function Text({ as, className, children, ...rest }) {
  if (!as) {
    // Check for "tag flag" syntax
    let keys = Object.getOwnPropertyNames(rest);
    // Default to <p> tag if no tag prop found and 'as' is not set
    let tag = 'p';
    
    checkTagsLoop: 
    for (const key of keys) {
      switch (key) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
        case 'small':
        case 'p':
          // Set the tag to the key string
          tag = key;
          // Break from the outer loop
          break checkTagsLoop;
        default:
          break;
      }
    }
    return React.createElement(
      tag,
      { className },
      [children]
    );
  } else {
    // Handle any other 'as' value (tag type)
    return React.createElement(
      as,
      { className },
      [children]
    );
  }
}

export default Text;