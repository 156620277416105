import React from 'react'
import { Link as GatsbyLink } from 'gatsby';
import { classdFn as classNames } from 'classd';
import { useFocusRing } from '@react-aria/focus';
// import Tooltip from './Tooltip';
import Tippy from '@tippyjs/react/headless';

import * as styles from '@styles/components/link.module.scss';

import * as indexStyles from '@styles/pages/index.module.scss';

import { useLocation } from '@reach/router';


function Link(props) {
  const { 
    to, 
    className, 
    external, 
    tipText,
    disabled, 
    disableDefaultClass, 
    focusClassName,
    disableTip = true, 
    children,
    id,
    newtab,
    ...rest 
  } = props;

  const location = useLocation();

  let { isFocusVisible, focusProps } = useFocusRing();

  const classes = classNames(
    disableDefaultClass ? null : styles.link,
    className,
    // disabled ? 'Link--disabled' : null,
    isFocusVisible ? styles.focus : null,
    isFocusVisible && focusClassName ? focusClassName : null,
    isFocusVisible && id == 'index' ? indexStyles.pagebtnFocusAlt : null,
  );

  if (disabled) {
    const a11yProps = {
      tabIndex: '-1',
      role: 'button',
      'aria-disabled': 'true',
    };
    return <a className={classes} {...a11yProps}>{children}</a>
  }
  else if (external) {
    if (newtab) {
      return (
        <a {...focusProps} 
          target="_blank" 
          rel="noopener noreferrer" 
          href={to} 
          className={classes} 
          style={rest.style}
        >
          {children}
        </a>
      );
    }
    return (
      <>
        {!disableTip && to !== '#'
          ? <LinkTip text={!tipText ? parseUrl(to) : tipText}>
              <a {...focusProps} href={to} className={classes}>{children}</a>
            </LinkTip>
          : <a {...focusProps} href={to} className={classes} style={rest.style}>{children}</a>
        }
      </>
    );
  }
  else {
    return (
      <>
        {!disableTip && to !== '#'
          ? <LinkTip text={!tipText ? parseUrl(to) : tipText}>
              <GatsbyLink 
                {...focusProps} 
                to={to}
                state={{prev: location.pathname}}
                className={classes} 
                {...rest}
              >
                {children}
              </GatsbyLink>
            </LinkTip>
          : <GatsbyLink
              {...focusProps}
              to={to}
              state={{prev: location.pathname}}
              className={classes} 
              {...rest}
            >
              {children}
            </GatsbyLink>
        }
      </>
    );
  }
}

function LinkTip({ text, children }) {
  const tippyProps = {
    placement: 'bottom-start',
    delay: [1500, 0], 
    offset: [0, 5],
  };

  if (text.path || text.domain) {
    return (
      <Tippy 
        {...tippyProps}
        render={attrs => ( 
          <div className="Link__Tip" tabIndex="-1" {...attrs}>
            {text.domain 
              ? <span className="Link__TipText">{`${text.domain}`}</span>
              : null
            }
            {text.path
              ? <span className={`Link__TipText${text.domain ? '--subtle' : ''}`}>
                  {text.path}
                </span>
              : null
            }
          </div>
      )}>
        {children}
      </Tippy>
    );
  } else {
    return (
      <Tippy 
        {...tippyProps}
        render={attrs => ( 
          <div className="Link__Tip" tabIndex="-1" {...attrs}>
            <span className="Link__TipText">{text}</span>
          </div>
      )}>
        {children}
      </Tippy>
    );
  }
  
}

function parseUrl(url) {
  let path = '';
  let domain = '';

  // Check if external; if it is then
  // remove scheme (protocol, e.g. 'https://'),
  if ((path = url.split('//')[1])) {
    // Assume there's a domain because we saw a protocol
    domain = path.split('/').shift();
    path = path.substr(domain.length);
    // Remove 'www.' if exists
    if (domain.split('.')[0] === 'www') domain = domain.substr(4);
  } else {
    path = url;
  }

  // Trim path string so it doesn't get too long
  // Note: implicit 4 extra chars for the collapsed paths with '...' and '/'
  if ((domain.length + path.length) > 48) {
    path = 
      `/${String.fromCharCode(8230)}/` +
      path.split('/').pop().substr(0, (48 - domain.length)) +
      String.fromCharCode(8230);

    console.log(`${domain}${path}`, domain.length + path.length, domain.length, path.length);
  }
  
  // path = ((domain.length + path.length) > 48) 
  //   ? path.substr(0, 48) + String.fromCharCode(8230) 
  //   : path;

  return { domain: domain, path: path };
}

export default Link;