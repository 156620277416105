import { Howl, Howler } from 'howler';
import tracklist from '../data/tracks.json';
import paths from '../data/paths.json';
// import projects from '../data/projects.json';
// console.log(projects);

// const sound = new Howl({
//   src: ['/test/track.mp3']
// });

// sound.play();

class Player {
  constructor({global = true, autoplay = false, queue = false}) {
    // this.tracks = tracklist;
    Howler.volume(0.7);
    Howler.autoUnlock = true;

    // TODO: in Layout, make this be changed when necessary
    this.global = global;
    this.autoplay = autoplay;
    this._volume = 0.7;

    // Build the track list and queue
    this.tracks = [];
    this.queue = [];

    let path = paths.assets + '/tracks/';

    for (let i = 0; i < tracklist.length; i++) {
      let name = tracklist[i].track;
      let artist = tracklist[i].artist;
      // let id = (tracklist[i].track + ' ' + tracklist[i].artist)
      //   .replace(/[^a-zA-Z0-9 ]/g, '')
      //   .replace(' ', '-');
      let id = tracklist[i].id;

      // let shouldAutoplay = false;
      // if (!global && autoplay && queue) {
      //   if (id === queue[0].id) shouldAutoplay = true;
      // } else if (i === 0 && global && autoplay) {
      //   shouldAutoplay = true;
      // }
      // (((i === 0) && this.autoplay) ? true : false)

      let sound = new Howl({
        src: [`${path}${id}.mp3`],
        onplay: () => console.log(`🎶 Now Playing: '${id}'`),
        onend: () => { 
          if (this.global) this.next();
          else this.stop();
        },
        onload: () => {
          this.tracks[i].length = Math.round(sound.duration())
        },
      });

      this.tracks.push({
        id: id,
        ...tracklist[i],
        length: false,
        sound: sound,
      })

      this.queue.push(id);
    }

    if (!global) {
      if (queue) this.queue = [ ...queue ];
      this.current = { 
        ...this.getTrack(this.queue[0]) 
      };

    } else {
      this.current = { ...this.tracks[0] };
    }
    
    this.savedQueue = null;

    // Default current to first in queue 
    // console.log('check', this.current, this.queue);
    // if (this.autoplay) this.current.sound.play();
  }

  seek(time) {
    if (time) this.current.sound.seek(time);
    else return this.current.sound.seek();
  }

  volume(value) {
    if (value) {
      this._volume = value;
      Howler.volume(value);
    }
    return this._volume;
  }
  mute(mute) {
    Howler.mute(mute);
    // if (!mute) {
    //   Howler.mute(true);
    // }
  }

  fade(mode, duration, stop) {
    switch (mode) {
      case 'out':
        this.current.sound.fade(1, 0, duration);
        // setTimeout(()=>{
        //   this.stop();
        // }, duration)
        break;
      case 'in':
        this.current.sound.fade(0, 1, duration);
        break;
    }
  }

  isGlobal() {
    return (this.global === true);
  }

  setGlobal(global) {
    if (!global) {
      this.saveQueue();
    } else {
      if (!this.loadQueue()) {
        this.resetQueue();
      }
    }
    this.global = global;
    // console.log('setGlobal:', this.global, this.queue, this.current.id);
  }

  findTrackIndex(id, arr) {
    if (arr) return arr.findIndex(track => track === id);
    return this.queue.findIndex(track => track === id);
  }

  findTrack(id) {
    return this.tracks.find(track => track.id === id);
  }

  setTrackInfo(index) {
    this.tracks[index].length = this.tracks[index].sound.duration();
  }

  getTrack(trackID) {
    // console.log(this.current, this.global);
    if (trackID) return this.findTrack(trackID);

    // let { id, name, artist, length } = this.current;
    return this.current;
  }

  getQueue() {
    return this.queue;
  }

  setTrack(id) {
    // If already current, ignore
    if (this.current.id === id) return;
    this.stop();
    // Else, set new current
    let index = this.findTrackIndex(id);
    let newTrack = { ...this.findTrack(id) };

    if (this.global) {
      this.updateQueue({
        index: index, 
        track: newTrack
      });
    }
    
    // Set the new track
    this.current = newTrack;
  }

  updateQueue(next) {
    let curr = { ...this.current };
    let queue = [ ...this.queue ];
    let skipCount = next.index - this.findTrackIndex(curr.id);
    let skipped = queue.splice(0, skipCount);
    this.queue = queue.concat(skipped);
    if (this.global) this.saveQueue();
  }

  resetQueue() {
    this.queue = [];
    for (let t of this.tracks) {
      this.queue.push(t.id);
    }
  }

  zeroQueue() {
    this.queue = [];
  }

  setQueue(queue) {
    this.queue = [ ...queue ];
  }

  // TODO: figure out a way to save the "global" queue so it can be reloaded 
  saveQueue() {
    this.savedQueue = [ ...this.queue ];
  }

  loadQueue() {
    if (this.savedQueue) {
      this.queue = [ ...this.savedQueue ];
      return true;
    } else return false;
  }

  play(id, fromQueue) {
    if (fromQueue) {
      this.setTrack(this.queue[0]);
    } else {
      if (id && this.current.id !== id) this.setTrack(id)
    }

    if (!this.current.sound.playing()) {
      // console.log('Player: play');
      this.current.sound.play();
    }
  }

  pause() {
    // console.log('Player: pause');
    this.current.sound.pause();
  }

  stop() {
    this.current.sound.stop();
  }

  next() {
    // Stop current track
    this.stop();
    if (this.queue.length === 1) {
      return;
    } 
    
    // Set the new track as current
    this.setTrack(this.queue[1]);
    
    // Play the new track (conditionally)
    if (this.global) {
      setTimeout(()=>{
        this.play();
      }, 1000);
      // clearTimeout(timer);
    }
  }

  // prev() {
  //   if (this.tracks.length === 1) {
  //     return;
  //   } 
  //   let nextIndex = this.findTrackIndex(this.current.id) - 1;
  //   if (nextIndex === this.tracks.length) {
  //     // Go to end
  //     nextIndex = this.tracks.length - 1;
  //   }
  //   // Stop current track
  //   this.stop();
  //   // Set the new track as current
  //   this.setTrack(this.tracks[nextIndex].id);
  //   // Play the new track (conditionally)
  //   if (this.global) this.play();
  // }
}

export default Player;