import React, { useRef, useEffect } from 'react';
import { useSlider, useSliderThumb } from '@react-aria/slider';
import { useSliderState}  from '@react-stately/slider';
import { useFocusRing } from '@react-aria/focus';
import { useFocusWithin } from '@react-aria/interactions'
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { mergeProps } from '@react-aria/utils';
import { useNumberFormatter } from '@react-aria/i18n';
import { classdFn as classNames } from 'classd';
import * as styles from '@styles/components/slider.module.scss';
import { Howler } from 'howler';

// DOCS: add note about default props handled by react-aria:
// e.g., default min, max, and step: https://github.com/adobe/react-spectrum/blob/c66852948e2eb7427ce24d4f3199e53c4f615919/packages/%40react-stately/slider/src/useSliderState.ts#L128
// Also note about which props are consumed by react-aria. Maybe in the docs, displaying as a  table would be simple enough

function Slider(props) {
  let {
    
    /* eslint-disable no-unused-vars */
    // Consumed by react-aria, but not directly by this component
    defaultValue,
    value,
    minValue,
    maxValue,
    step,
    onChange,
    formatOptions,
    /* eslint-enable no-unused-vars */
    label,
    minValueLabel,
    className,
    maxValueLabel,
    displayUnit,
    hideOutput,
    disableNumberInput,
    hideMinMaxLabels,
    simple,
    showValueOnHover,
    onMouseDown,
    onMouseUp,
  } = props;

  // react-aria hooks setup
  let trackRef = useRef(null);
  let { focusProps, isFocusVisible } = useFocusRing();
  let numberFormatter = useNumberFormatter(formatOptions);
  let state = useSliderState({ ...props, numberFormatter });
  let { groupProps, trackProps, labelProps, outputProps } = useSlider(
    props,
    state,
    trackRef
  );
  
  return (
    <div className={classNames(className, styles.slider)} {...groupProps} aria-label={label}>
      {/* <div className={styles.labelWrapper}>
        {label && 
          <label className={styles.label} {...labelProps}>{label}</label>
        }
      </div> */}
      <div className={styles.sliderGroup}>
        <Track
          state={state}
          isFocusVisible={isFocusVisible}
          trackProps={trackProps}
          trackRef={trackRef}
          minValueLabel={minValueLabel}
          maxValueLabel={maxValueLabel}
          displayUnit={displayUnit}
          hideMinMaxLabels={simple ? simple : hideMinMaxLabels}
          displayUnit={displayUnit}
        >
          <Thumb
            index={0}
            state={state}
            trackRef={trackRef}
            focusProps={focusProps}
            isFocusVisible={isFocusVisible}
            displayUnit={displayUnit}
            showValue={showValueOnHover}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
          />
        </Track>
        {!hideOutput && !simple &&
          <OutputArea 
            state={state}
            outputProps={outputProps}
            displayUnit={displayUnit}
            disableNumberInput={disableNumberInput}
          />
        }
      </div>
    </div>
  );
}

function OutputArea({ state, outputProps, displayUnit, disableNumberInput }) {
  const inputRef = useRef(null);

  // For focus state styling
  let [isFocusWithin, setFocusWithin] = React.useState(false);
  let {focusWithinProps} = useFocusWithin({
    onFocusWithinChange: (isFocusWithin) => setFocusWithin(isFocusWithin)
  });

  const valueWrapperClasses = classNames(
    styles.valueWrapper, 
    isFocusWithin
      ? styles.valueWrapperFocus
      : null,
  );

  if (disableNumberInput) {
    return (
      <div className={styles.valueWrapper}>
        <output
          className={styles.value} {...outputProps}
          style={{minWidth: `${state.getFormattedValue(state.getThumbMaxValue(0)).length}ch`}}
        >
          {`${state.getFormattedValue(state.getThumbValue(0))}${displayUnit ? displayUnit : ''}`}
        </output>
      </div>
    );
  } else {
    return (
      <div 
        className={valueWrapperClasses}
        {...focusWithinProps}
      >
        <div className={styles.numberInput}>
          <input
            type="number"
            className={styles.value} 
            style={{minWidth: `${state.getFormattedValue(state.getThumbMaxValue(0)).length}ch`}}
            value={state.getThumbValue(0)}
            step={state.step}
            min={state.getThumbMinValue(0)}
            max={state.getThumbMaxValue(0)}
            onChange={(e) => state.setThumbValue(0, e.target.value)}
            ref={inputRef}
            {...outputProps}
          />
          {displayUnit && <span className={styles.valueUnit}>{displayUnit}</span>}
        </div>
      </div>
    );
  }
}

function Track(props) {
  let { 
    state, 
    isFocusVisible, 
    trackProps, 
    trackRef, 
    children, 
    hideMinMaxLabels,
    minValueLabel,
    maxValueLabel,
  } = props;
  const drag = state.isThumbDragging(0);

  let trackClasses = classNames(
    styles.track,
    drag
      ? styles.trackDrag
      : null,
  );

  let trackValueClasses = classNames(
    styles.trackValue,
    isFocusVisible
      ? styles.trackValueFocus
      : null,
    drag
      ? styles.trackValueDrag
      : null,
  );

  return (
    <div
      className={styles.trackWrapper}
    >
      {!hideMinMaxLabels
        ? <span className={styles.trackMinValue}>
            {minValueLabel ? minValueLabel : state.getFormattedValue(state.getThumbMinValue(0))}
          </span>
        : <span className={styles.trackHiddenLabel}>&nbsp;</span>
      }

      <div className={styles.trackInner}>
        <div
          className={trackValueClasses}
          style={{
            width: `${state.getThumbPercent(0) * 100}%`,
          }}
        />
        <div 
          className={styles.trackTarget}  
          ref={trackRef}
          {...trackProps}
        >
          <div className={trackClasses} />
        </div>
        {children}
      </div>

      {!hideMinMaxLabels
        ? <span className={styles.trackMaxValue}>
            {maxValueLabel ? maxValueLabel :state.getFormattedValue(state.getThumbMaxValue(0))}
          </span>
        : <span className={styles.trackHiddenLabel}>&nbsp;</span>
      }
    </div>
  );
}

function Thumb(props) {
  let { 
    state, 
    trackRef, 
    index, 
    isFocusVisible, 
    focusProps, 
    showValue ,
    onMouseDown,
    onMouseUp,
  } = props;
  let inputRef = useRef(null);
  let thumbRef = useRef(null);
  let { thumbProps, inputProps } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef
    },
    state
  );

  // FIX: this causes console warning
  // Basically a setState bug
  // possibly copy above useFocusRing code to Slider,
  // and pass down focusProps to be rendered here
  // and isFocusVisible to Track and here to both use it for styling
  // handleThumbFocusVisible(isFocusVisible);

  const thumbClasses = classNames(
    styles.thumb,
    isFocusVisible
      ? styles.thumbFocus 
      : state.isThumbDragging(index)
      ? styles.thumbDrag
      : null,
  );

  const thumbValueClasses = showValue ? classNames(
    styles.thumbValue,
    isFocusVisible
      ? styles.thumbValueFocus 
      : state.isThumbDragging(index)
      ? styles.thumbValueDrag
      : null,
  ) : null;

  useEffect(()=>{
    if (state.isThumbDragging(index)) {
      if (onMouseDown) onMouseDown();
    } else {
      if (onMouseUp) onMouseUp();
    }
  });

  // if (isFocus && !isFocusVisible) handleFocusChange(true)
  // else handleFocusChange(false);

  return (
    <div
      className={styles.thumbWrapper}
      // style={{ left: `${left}px` }}
      style={{ left: `${state.getThumbPercent(index) * 100}%` }}
      ref={thumbRef}
    >
      <div className={thumbClasses} {...thumbProps}>
        <VisuallyHidden>
          <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
        </VisuallyHidden>
      </div>
      {showValue &&
        <output className={thumbValueClasses}>
          {state.getFormattedValue(state.getThumbValue(0))}
        </output>
      } 
    </div>
  );
}

export default Slider;