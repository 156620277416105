import React, { useRef, useEffect } from 'react';
import { classdFn as classNames } from 'classd';
import * as styles from '@styles/components/view.module.scss';
// import { TransitionGroup, Transition } from "react-transition-group";

export default function View(props) {

  const {
    children,
    onClick,
    hasNav,
    hasPlayer,
    view
  } = props;

  const content = React.cloneElement(children, {
    player: props.player,
    queue: props.queue,
    setQueue: props.setQueue,
    data: props.data,
    setlist: props.setlist,
  });

  const ref = useRef();

  useEffect(()=>{
    ref.current.scrollTop = 0;
  }, [view])


  // const timeout = 500
  // let getTransitionStyles = {
  //   entering: {
  //     position: `absolute`,
  //     opacity: 0,
  //     // transform: `translateX(16px)`,
  //   },
  //   entered: {
  //     // transform: `translateX(0px)`,
  //     transition: `opacity ${timeout}ms ease-in-out`,
  //     opacity: 1,
  //   },
  //   exiting: {
  //     // transform: `translateX(-16px)`,
  //     transition: `opacity ${timeout}ms ease-in-out`,
  //     opacity: 0,
  //   },
  // }

  return (
    <>
      {/* <TransitionGroup component={null}>
        <Transition
          key={props.location.pathname}
          timeout={{
            enter: timeout,
            exit: 250,
          }}
        >
          {status => ( */}
            <div 
              ref={ref}
              id="view" 
              onClick={onClick} 
              className={classNames(
                styles.container, 
                !hasNav ? styles.nonav : null,
                !hasPlayer ? styles.noplayer : null,
              )}
              // style={{
              //   ...getTransitionStyles[status],
              // }}
            >
              {content}
            </div>
          {/* )} 
         </Transition>
      </TransitionGroup> */}
    </>
  );
}